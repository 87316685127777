import contentStyles from './downloadButton.module.css';
import React from 'react';

const DownloadButton = (props) => {

  const imageSrc = (() => {
    switch (props.type) {
      case 'xls':
        switch (props.lang) {
          case 'en':
            return '/images/xls-download-icon-en.svg';
          default:
            return '/images/xls-download-icon-de.svg';
        }
      default:
        switch (props.lang) {
          case 'en':
            return '/images/pdf-download-icon-en.svg';
          default:
            return '/images/pdf-download-icon-de.svg';
        }
    }
  })();

  const href = props.href;

  // const href = (props.lang === 'en')
  //     ? 'pdfs/Flexible Hoses and Ducting - Product Catalogue 2020.pdf'
  //     : 'pdfs/Flexible Hoses and Ducting - Product Catalogue 2020.pdf';

  return (
      <a href={href}
         className={`${contentStyles.downloadButton} ${props.className}`}
         target="_blank" download
         rel="noreferrer"
         data-sal="slide-up"
         data-sal-delay="300"
         data-sal-easing="ease">
        <img src={imageSrc}
             alt="PDF"/>
      </a>
  );
};

export default DownloadButton;
